















import SearchParams from '@/models/SearchParams'
import Vue from 'vue'

export default Vue.extend({
  name: 'SearchForm',
  data() {
    const data: SearchParams = {
      ticketNumber: '',
      serialNumber: '',
      department: ''
    }
    return data
  }
})
