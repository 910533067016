

















import Vue from 'vue'
import { getTickets } from '../api/ticketApi'
import Loading from '../components/Loading.vue'
import SearchResult from '../components/SearchResult.vue'
import Ticket from '../models/Ticket'

export default Vue.extend({
  name: 'Results',
  components: {
    Loading,
    SearchResult
  },
  props: {
    ticketNumber: {
      type: String,
      default: () => ''
    },
    serialNumber: {
      type: String,
      default: () => ''
    },
    department: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      isLoading: true,
      results: [] as Ticket[]
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      var foundTickets = await getTickets({ ticketNumber: this.ticketNumber, serialNumber: this.serialNumber, department: this.department })
      this.results.push(...foundTickets)
    } catch (error) {
      console.error('Could not retrieve tickets : ' + error)
    } finally {
      this.isLoading = false
    }
  }
})
