










import Vue from 'vue'
import Footer from './components/Footer.vue'
import NavBar from './components/NavBar.vue'
import eventBus, { GlobalEvents } from './util/eventBus'

export default Vue.extend({
  name: 'app',
  components: {
    NavBar,
    Footer
  },
  methods: {
    keydown() {
      eventBus.$emit(GlobalEvents.escapePushed)
    }
  }
})
