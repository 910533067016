































import eventBus, { GlobalEvents } from '@/util/eventBus'
import Vue from 'vue'

export default Vue.extend({
  name: 'Modal',
  props: {
    headerLabel: {
      type: String,
      default: ''
    },
    bodyLabel: {
      type: String,
      default: ''
    },
    closeButtonLabel: {
      type: String,
      default: 'Fermer'
    }
  },
  computed: {
    currentDate() {
      return new Date().getFullYear()
    }
  },
  mounted() {
    eventBus.$on(GlobalEvents.escapePushed, () => {
      this.$emit('close')
    })
  }
})
