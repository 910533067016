import axios from 'axios'
import SearchParams from '@/models/SearchParams'
import Ticket from '@/models/Ticket'

export async function getTickets(searchParams: SearchParams): Promise<Ticket[]> {
  return (
    await axios.get('/api/ticket', {
      params: searchParams
    })
  ).data
}
