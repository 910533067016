











import Vue from 'vue'
import Modal from '../components/Modal.vue'
import SearchForm from '../components/SearchForm.vue'
import SearchParams from '../models/SearchParams'

export default Vue.extend({
  name: 'Search',
  components: {
    Modal,
    SearchForm
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    search({ ticketNumber, serialNumber, department }: SearchParams) {
      const fieldCount =
        (ticketNumber && ticketNumber.length > 0 ? 1 : 0) +
        (serialNumber && serialNumber.length > 0 ? 1 : 0) +
        (department && department.length > 0 ? 1 : 0)
      if (fieldCount < 2) {
        this.showModal = true
      } else {
        this.$router.push({
          name: 'Results',
          query: { ticketNumber, serialNumber, department }
        })
      }
    }
  }
})
