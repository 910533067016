import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Error from '../views/Error.vue'
import Search from '../views/Search.vue'
import Results from '../views/Results.vue'
import Legal from '../views/Legal.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Search',
    component: Search
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
    props: route => route.query
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      title: 'Erreur',
      displayIfExpired: true
    },
    props: true
  },
  {
    path: '*',
    name: 'NotFound',
    component: Error,
    meta: {
      title: 'Page inconnue'
    },
    props: {
      code: 404
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
