











import Vue from 'vue'

export default Vue.extend({
  name: 'Footer',
  computed: {
    currentDate() {
      return new Date().getFullYear()
    }
  }
})
