















import Vue from 'vue'

export default Vue.extend({
  name: 'Error',
  props: {
    code: Number,
    details: String
  },
  computed: {
    currentUrl() {
      return window.location.href
    }
  }
})
