import Ticket from '@/models/Ticket'
import LocalizedItem from './LocalizedItem'
import problemTypes from './problemTypes.json'
import resolutionTypes from './resolutionTypes.json'
import serviceStatusNames from './serviceStatusNames.json'
import subProblemTypes from './subProblemTypes.json'

export function formatDate(date: string): string {
  let formated = ''
  if (date && date.length >= 16) {
    // Input format : 2021-11-29T13:54:00 => output format : 29/11/2021 13:54
    formated = `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(0, 4)} ${date.substring(11, 13)}:${date.substring(14, 16)}`
  }
  return formated
}

export function searchStatus(ticket: Ticket): LocalizedItem | undefined {
  return serviceStatusNames.find(i => i.id == ticket.statusID)
}

export function searchProblemType(ticket: Ticket): LocalizedItem | undefined {
  return problemTypes.find(i => i.id == ticket.problemTypeID)
}

export function searchProblemSubType(ticket: Ticket): LocalizedItem | undefined {
  return subProblemTypes.find(i => i.id == ticket.problemSubTypeID)
}

export function searchProblemResolutionType(ticket: Ticket): LocalizedItem | undefined {
  return resolutionTypes.find(i => i.id == ticket.problemResolutionTypeID)
}

export function formatStatus(ticket: Ticket): string {
  const match = searchStatus(ticket)
  return match ? match.french : ticket.statusName
}

export function formatProblemType(ticket: Ticket): string {
  const match = searchProblemType(ticket)
  return match ? match.french : ticket.problemTypeName
}

export function formatProblemSubType(ticket: Ticket): string {
  const match = searchProblemSubType(ticket)
  return match ? match.french : ticket.problemSubTypeName
}

export function formatProblemResolutionType(ticket: Ticket): string {
  const match = searchProblemResolutionType(ticket)
  return match ? match.french : ticket.problemResolutionTypeName
}
