

























































import Vue from 'vue'
import Modal from '../components/Modal.vue'
import Ticket from '../models/Ticket'
import {
  formatDate,
  searchStatus,
  searchProblemType,
  searchProblemSubType,
  searchProblemResolutionType,
  formatStatus,
  formatProblemType,
  formatProblemSubType,
  formatProblemResolutionType
} from '../localization/format'

export default Vue.extend({
  name: 'SearchResult',
  components: {
    Modal
  },
  props: {
    ticket: {
      type: Object as () => Ticket
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    formatDate,
    searchStatus,
    searchProblemType,
    searchProblemSubType,
    searchProblemResolutionType,
    formatStatus,
    formatProblemType,
    formatProblemSubType,
    formatProblemResolutionType
  }
})
